<template>
  <div class="dm_landing_bg" style="background:#FFF4F1;">
    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg static-top">
      <div class="container">
        <a class="navbar-brand" href="#"><img class="dm_logo" src="../../assets/img/logo.png" alt=""></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
          aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/privacy-policy">Privacy Policy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/terms-of-use">Terms Of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Page Content -->
    <div class="h-100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div v-html="description"></div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<style>
</style>

<script>

export default {
    name: "terms-of-use",
    data: () => ({
     slug: 'terms-of-use',
     description: '',
    }),
    methods: {
      async getStaticPage() {
        let form = {
          slug : this.slug
        };
        let responce = await this.$store.dispatch("getStaticPage", form);
        if(responce.status){
            this.description = responce.data.data.description;
        }
      }
    },
    created() {
      this.getStaticPage();
    }
};
</script>
